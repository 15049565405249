import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/vercel/path0/middleware/admin.ts"),
  app: () => import("/vercel/path0/middleware/app.ts"),
  auth: () => import("/vercel/path0/middleware/auth.ts"),
  classrooms: () => import("/vercel/path0/middleware/classrooms.ts"),
  "student-app": () => import("/vercel/path0/middleware/student-app.ts"),
  sysadmin: () => import("/vercel/path0/middleware/sysadmin.ts")
}