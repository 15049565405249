<script lang="ts" setup>
const props = defineProps({
  color: {
    type: String,
    default: "primary",
  },
  tooltip: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  showSpinner: {
    type: Boolean,
    default: false,
  },
  size: {
    type: Number,
    default: 30,
  },
});

const dynamicStyles = computed(() => {
  const styles: any = {};

  switch (props.color) {
    case "secondary":
      styles[`text-secondary-default`] = true;
      break;
    case "google":
      styles[`text-google-default`] = true;
      break;
    case "danger":
      styles[`text-danger-default`] = true;
      break;
    case "primary":
    default:
      styles[`text-primary-default`] = true;
      break;
  }

  return styles;
});
</script>

<template>
  <span
    class="relative flex flex-col align-center items-center p-2 justify-center rounded hover:bg-medium-default hover:bg-opacity-10 transition-all duration-150 cursor-pointer"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }"
    :class="{
      ...dynamicStyles,
      '!text-medium-tint !active:bg-opacity-10': disabled,
      ' !active:bg-opacity-20': disabled != true,
    }"
    v-tooltip="tooltip"
  >
    <slot />
    <div
      v-if="showSpinner"
      class="absolute top-0 left-0 w-full h-full bg-medium-default rounded flex items-center justify-center text-primary-default bg-opacity-20 "
    >
      <icon name="svg-spinners:ring-resize" />
    </div>
  </span>
</template>

<style scoped></style>
