<script setup lang="ts">
import { useCurrentUser } from "vuefire";

// Setting the database to ignore undefined properties by default.
const db = useFirestore();
(db as any)._settings.ignoreUndefinedProperties = true;

const router = useRouter();
const route = useRoute();
const user = useCurrentUser();

// we don't need this watcher on server
onMounted(async () => {
  watch(user, (user, prevUser) => {
    initBugSnagUser();

    if (prevUser && !user) {
      // user logged out
      router.push("/home");
    } else if (user && typeof route.query.redirect === "string") {
      // user logged in
      router.push(route.query.redirect);
    }
  });

  initBugSnagUser();
});

const initBugSnagUser = () => {
  if (!user.value) return;
  useBugsnag().setUser(user.value.uid);
};
</script>

<template>
  <div>
    <ConnectivityMonitorWrapper class="block mobile:hidden">
      <GuidedToursWrapper>
        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
      </GuidedToursWrapper>
    </ConnectivityMonitorWrapper>
    <BaseMobileDisclaimer class="hidden mobile:flex w-full h-full" />
  </div>
</template>

<style>
html,
body {
  font-family: lato;

  @apply text-dark;
  @apply bg-background;
}

.v-popper__popper {
  z-index: 9999 !important;
}

.v-toast {
  z-index: 9999999 !important;
}

.v-toast__item--success {
  @apply bg-success-default !important;
}

/* Class assigned to popover wrapper */
.driver-popover {
  @apply rounded !important;
  max-width: 400px;
  max-height: 90vh;
  @apply flex !important;
  @apply flex-col !important;
}

.driver-popover.max-width-800 {
  max-width: 800px !important;
}

.driver-popover.max-width-600 {
  max-width: 600px !important;
}

.driver-popover.max-width-500 {
  max-width: 500px !important;
}

.driver-popover.bottom {
  bottom: 0px !important;
  left: 50% !important;
  width: 100% !important;
  max-height: 200px !important;
  overflow: auto !important;
  max-width: 900px !important;
  transform: translateX(-50%) !important;
}

/* Arrow pointing towards the highlighted element */
.driver-popover-arrow {
}

/* Title and description */
.driver-popover-title {
  @apply text-h4 !important;
  @apply font-bold !important;
}

.driver-popover-description {
  @apply text-body-1 !important;
  @apply grow;
  @apply min-h-0;
  @apply overflow-auto;
}

/* Close button displayed on the top right corner */
.driver-popover-close-btn {
}

/* Footer of the popover displaying progress and navigation buttons */
.driver-popover-footer {
}
.driver-popover-progress-text {
}
.driver-popover-prev-btn {
}
.driver-popover-next-btn {
  @apply bg-primary-default !important;
  @apply text-primary-on !important;
  @apply font-normal !important;
  @apply rounded !important;
  @apply px-4 !important;
  @apply py-2 !important;
  @apply hover:bg-primary-shade !important;
  text-shadow: none !important;
  @apply outline-none !important;
}

.dp__input_reg {
  @apply border !important;
  @apply border-medium-tint !important;
  @apply rounded !important;
  @apply p-2 !important;
  @apply pl-8 !important;
  @apply focus:border-primary-default !important;
  @apply focus:outline-none !important;
}

.dp__theme_light {
  --dp-primary-color: #2b5076 !important;
  --dp-action-button-height: 32px !important;
  --dp-action-button-border-radius: 2px !important;
  --dp-action-buttons-padding: 0px 24px !important;
  --dp-common-padding: 4px 8px !important;
}
</style>
