<script setup lang="ts">
type CustomError = {
  url: string;
  statusCode: number;
  statusMessage: string;
  message: string;
  description: string;
  data: any;
};

const props = defineProps({
  error: Object as PropType<CustomError>,
});

const title = computed(() => {
  if (data.value.title) {
    return data.value.title;
  }

  if (props.error?.statusCode == 401) {
    return "Unauthorized";
  }

  if (props.error?.statusCode == 403) {
    return "Unahtorized";
  }

  if (props.error?.statusCode == 404) {
    return "Page Not Found";
  }

  return "Oops! Something went wrong";
});

const data = computed(() => {
  try {
    return JSON.parse(props.error?.data ?? "{}");
  } catch (error) {
    return {};
  }
});

const message = computed(() => {
  if (data.value.message) {
    return data.value.message;
  }

  if (props.error?.statusCode == 401) {
    return "You are not authorized to access this page.";
  }

  if (props.error?.statusCode == 403) {
    return "You are not authorized to access this page.";
  }

  if (props.error?.statusCode == 404) {
    return "The page you are looking for does not exist.";
  }

  return "An unexpected error occurred.";
});
</script>

<template>
  <div v-if="error" class="h-screen w-screen flex flex-col items-center p-12">
    <img src="/img/logo.png" class="my-4 w-[130px]" >
    <h2 class="text-h2">
      {{ title }}
    </h2>
    <p class="text-h4 text-center mt-4">
      {{ message }}
    </p>
    <NuxtLink to="/" class="mt-4">
      <BaseTextButton> Home Page </BaseTextButton>
    </NuxtLink>
  </div>
</template>
