<script lang="ts" setup>
import { GuidedTour } from "classes/models/tours/guided-tour";

const props = defineProps({
  guidedTour: {
    type: Object as PropType<GuidedTour>,
    required: true,
  },
});

const guidedTourStore = useGuidedTours();
const { showTourButton, tourToShow } = storeToRefs(guidedTourStore);

const startTour = (tour: GuidedTour) => {
  useGuidedTours().setTourToShow(undefined);
  useGuidedTours().startTour(tour);
};

const closeTour = () => {
  useGuidedTours().setTourToShow(undefined);
  useTourEventBus().emit({
    name: GuidedTourEventName.tourComplete,
    data: props.guidedTour.id,
  });
};

// get the current route as a computed value that is reactive
const route = useRoute();
const currentPath = computed(() => {
  return route.path;
});

const isAvailable = computed(() => {
  if (
    props.guidedTour.restrictedPath == undefined ||
    props.guidedTour.restrictedPath == ""
  )
    return true;

  return props.guidedTour.restrictedPath == currentPath.value;
});
</script>

<template>
  <!-- <div
    v-if="isAvailable && guidedTour.tourPromptType == 'button'"
    class="fixed bottom-4 rounded-full p-4 bg-secondary-default text-secondary-on cursor-pointer shadow hover:bg-secondary-tint"
    :style="{
      right: guidedTour.buttonRightPosition || undefined,
      left: guidedTour.buttonRightPosition == undefined ? '8px' : undefined,
    }"
    @click="startTour(guidedTour)"
  >
    {{ guidedTour.buttonLabel }}
  </div> -->
  <div
    class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 z-[9999]"
    v-if="isAvailable && guidedTour.tourPromptType == 'dialog'"
  >
    <div
      class="bg-surface rounded shadow absolute min-w-[500px] max-w-[600px] top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]"
    >
      <div class="border-b px-4 py-4 text-h4 font-bold">
        {{ guidedTour.dialogTitle }}
      </div>
      <div class="p-4 py-8">
        {{ guidedTour.dialogText }}
      </div>
      <div class="flex flex-row items-center border-t px-4 py-4 justify-end">
        <BaseTextButton @click="closeTour()" class="h-[30px] mr-2">
          No Thanks
        </BaseTextButton>
        <BaseButton @click="startTour(guidedTour)" class="h-[30px]">
          Start Tour!
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
